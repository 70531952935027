import { useCallback, useRef } from 'react';

import { useElementReachedEffect } from 'hooks/viewport/useElementReachedEffect';
import { useScrollEffect } from 'hooks/viewport/useScrollEffect';
import { CmsApiScrollTrackingBlockData } from 'modules/cms/api/types/blocks/CmsApiScrollTrackingBlock';
import { trackEvent } from 'utils/analytics/track/trackEvent';

type Props = {
  data: CmsApiScrollTrackingBlockData;
};

export function CmsScrollTrackingBlock({ data: { eventName } }: Props) {
  const scrolledRef = useRef(false);

  useScrollEffect(
    useCallback(() => {
      scrolledRef.current = true;
    }, []),
    { skipBootTrigger: true },
  );

  const ref = useRef<HTMLDivElement>(null);

  useElementReachedEffect({
    ref,
    onReach: useCallback(() => {
      const scrolled = scrolledRef.current;
      trackEvent(`Scroll: ${eventName}`, {
        reached_through: scrolled ? 'Scrolling' : 'Page Load',
      });
    }, [eventName]),
  });

  return <div ref={ref} />;
}
